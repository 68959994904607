<template>
    <v-container id="successStoriesContainer" class="pa-16 mb-n16" fluid>
        <v-col align="center" class="noSpace">
            <v-row align="center" justify="center" class="noSpace">
                <p class="centuryGothicBold screenTitle mb-n12">
                    CASOS DE ÉXITO
                </p>
            </v-row>

            <v-row align="center" class="noSpace" justify="center" style="height: auto;">
                <v-carousel
                    id="successStoriesCarrousel"
                    v-model="slide"
                    delimiter-icon="mdi-minus"
                    hide-delimiter-background
                    light
                >
                    <!-- SLIDER LEFT ARROW -->
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="sliderNavigationButtons"
                            color="secondary"
                            icon
                            large
                        >
                            <v-icon x-large>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>

                    <!-- SLIDER RIGHT ARROW -->
                    <template v-slot:next="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="sliderNavigationButtons"
                            color="secondary"
                            icon
                            large
                        >
                            <v-icon x-large>mdi-arrow-right</v-icon>
                        </v-btn>
                    </template>

                    <v-carousel-item
                        v-for="(video, index) in videos"
                        :key="index"
                    >
                        <v-row
                            align="center"
                            class="elementRow noSpace"
                            justify="center"
                        >
                            <iframe
                                :src="video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                class="youTubePlayer"
                                frameborder="0"
                                title="YouTube video player"
                            ></iframe>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "SuccessStories_Section",
    data: () => ({
        slide: 0,
        videos: [
            'https://www.youtube.com/embed/sxK7IYNbAXE',
            'https://www.youtube.com/embed/yabXlyRQOzE',
        ],
    }),
}
</script>

<style scoped>
.youTubePlayer {
    width: 60%;
    height: 70%;
}

button, .v-btn, .v-btn--icon {
    background-color: #EBEAEA !important;
    border: 2px solid #EBEAEA !important;
}

#successStoriesContainer {
    background-color: #EBEAEA;
}

#successStoriesCarrousel {
    width: 80%;
}

.elementRow {
    height: 100% !important;
}

@media (max-width: 960px) {
    #successStoriesContainer {
        background-color: #EBEAEA;
        padding: 30px 0 !important;
    }

    #successStoriesCarrousel {
        width: 95% !important;
        margin: 0 !important;
    }


    .elementRow {
        height: 100% !important;
    }

    .youTubePlayer {
        width: 65%;
        height: 50%;
    }
}
</style>