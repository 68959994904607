<template>
    <v-row
        :style="color === true ? 'background-color: #e6413d;' : 'background-color: #231F20;'"
        class="pa-0 ma-0"
        align="center"
        justify="center"
    >
        <v-col class="pa-0 ma-0" align="center">
            <!-- SECTION TITLE -->
            <p class="centuryGothicBold screenTitle mt-10 px-4" style="color: #fff !important; text-align: center">
                VENTAJAS DE LOS PRODUCTOS MEZCLA BRAVA
            </p>

            <!-- BENEFITS CARDS -->
            <v-row class="pa-0 ma-0" align="center" justify="center" id="cardsRow">
                <!-- CARD -->
                <v-col
                    v-for="(benefit, index) in benefitsList"
                    :key="index"
                    class="cardColumn"
                    sm="12"
                    xs="12"
                    md="6"
                    lg="6"
                    xl="6"
                >
                    <!-- BORDER IMAGE -->
                    <v-img
                        class="cardImage"
                        :src="require(`../../assets/cards/${benefit.name}-${selectedColorKey}.png`)"
                    >
                        <!-- CONTENT -->
                        <p
                            class="centuryGothic white--text cardText"
                        >
                            {{ benefit.description }}
                        </p>
                    </v-img>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Benefits_Section",
    props: [
        'color'
    ],
    created() {
        this.selectedColorKey = this.$props.color === true ? 'N' : 'R';
    },
    data() {
        return {
            selectedColorKey: '',
            benefitsList: [
                {
                    name: 'Tiempo',
                    description: 'Reduce tiempo de preliminares, acarreos, preparación y aplicación en comparación con la mezcla en sitio.',
                },
                {
                    name: 'Calidad',
                    description: 'Proceso Industrializado con agregados lavados y controlados, Calidra®, cemento CPO 40 y aditivos que aseguran el desempeño de nuestros productos de principio a fin.',
                },
                {
                    name: 'Rendimiento',
                    description: 'Reduce hasta un 90% los desperdicios en el mezclado y en la aplicación.',
                },
                {
                    name: 'Logistica',
                    description: 'Simplifica la logística de entrega, almacenamiento y distribución en obra.',
                },
                {
                    name: 'Limpieza',
                    description: 'Elimina escombros de arenas sueltas en obras al 100%, lo que permite disminuir considerablemente los trabajos de limpieza.',
                },
                {
                    name: 'Ahorro',
                    description: 'Al simplificar todos los procesos de obra tendrás un mayor rendimiento en materiales y personal, teniendo como resultado un ahorro económico.',
                },
            ],
        }
    }
}
</script>

<style scoped>
#cardsRow {
    padding: 0 20vh !important;
}

.cardColumn {
    padding: 40px;
}

.cardImage {
    width: 100%;
}

.cardText {
    margin-top: 11vh;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 0.95em !important;
}

@media (max-width: 960px) {
    #cardsRow {
        padding: 0 0 !important;
    }

    .cardColumn {
        margin-bottom: 20px;
        padding: 5px 20px;
    }

    .cardImage {
        width: 100%;
    }

    .cardText {
        margin-top: 7vh;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.92em !important;
    }
}
</style>